import React from 'react';
import styled from 'styled-components';
import RecordHistoryTableComponent from '../Component/HistoryPage/RecordHistoryTableComponent';
import useGetRecord from '../hook/useGetRecord';
import FullScreenCenterLayout from '../Layout/FullScreenCenterLayout';

function History() {
  const { recordHistory } = useGetRecord();

  return (
    <StyledFullScreenCenterLayout>
      <StyledLayoutContainer>
        <StyledTitleContainer>History</StyledTitleContainer>
        {recordHistory && (
          <RecordHistoryTableComponent recordHistory={recordHistory} />
        )}
      </StyledLayoutContainer>
    </StyledFullScreenCenterLayout>
  );
}

export default History;

const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height:100vh;
  width:100%;


  padding-top:12px;
  padding-bottom:80px;
`;

const StyledFullScreenCenterLayout = styled(FullScreenCenterLayout)`
  width: 100%;
  height: 100%;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 24px;
  font-weight: bold;
  color: #0f0f0f;
  margin-bottom: 2px;
`;
