import React from 'react';
import Fab from '@mui/material/Fab';
import { Stack } from '@mui/material';
import ClockComponent from '../Component/MainPage/ClockComponent';
import styled from 'styled-components';
import moment from 'moment';
import { firebaseDatabase } from '../Services/firebase/database';
import { auth } from '../firebase';
import Divider from '@mui/material/Divider';
import { FirebaseAuthContext } from '../provides/FirebaseAuthProvider/FirebaseAuthProvider';
import UserNameWelcome from '../Component/MainPage/UserNameWelcome';
import { defaultItems, itemIcons, items, Record } from '../config/event';
import { checkTodayDate } from '../util/time';
import RestoreIcon from '@mui/icons-material/Restore';
import Spinner from '../Component/Spinner';

const style = [
  'linear-gradient(to right bottom, #976984, #857299, #6a7ca4, #4f85a3, #448c97, #479291, #529888, #649c7d, #71a47a, #81ac75, #93b371, #a8b96c)',
  'linear-gradient(to right bottom, #c6c163, #c0b55f, #baa95b, #b39d58, #ab9255, #ac8b51, #ac834d, #ac7c4b, #b6754a, #c06d4c, #c96452, #d15b5b)',
  'linear-gradient(to right bottom, #cfe10f, #4ae270, #00d6bc, #00c1ea, #00a5ec, #4494ea, #6f7fe0, #9267cc, #bf51b4, #de3690, #ed2564, #eb3333)',
  'linear-gradient(to right bottom, #a10fe1, #0076ff, #009dff, #00b5e0, #00c6b8, #21c690, #5bc25c, #8db913, #b1a000, #cd8300, #e16001, #eb3333)',
  'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)',
  'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);',
];

function Main() {
  const { firebaseUser } = React.useContext(FirebaseAuthContext);

  const [styleIndex] = React.useState<number>(4);

  const [recordItems, setRecordItems] = React.useState<Record[] | null>(null);

  const recordEventTime = async (selectedItem: string) => {
    if (!firebaseUser) return;
    if (!recordItems) return;

    const currentTime = moment(new Date()).format('h:mm:ss a');
    firebaseDatabase.update(
      `${firebaseUser?.email
        ?.split('@')[0]
        .replace('.', '-')}/${checkTodayDate()}`,
      {
        [selectedItem]: currentTime,
      },
    );

    const disabledItem = recordItems
      ?.filter((item) => item.disabled)
      .map((item) => item.name);
    disabledItem?.push(selectedItem);
    const clonedRecordItems = [...recordItems];

    const modifiedRecordItems = clonedRecordItems.map((item) => {
      if (item.name === selectedItem) {
        return {
          name: selectedItem,
          disabled: true,
          icon: item.icon,
          finishedTime: currentTime,
        };
      } else {
        return item;
      }
    });
    setRecordItems(modifiedRecordItems);
  };

  React.useEffect(() => {
    if (!firebaseUser) return;

    async function checkDisabledItems() {
      const data = await firebaseDatabase.get(
        `${firebaseUser?.email
          ?.split('@')[0]
          .replace('.', '-')}/${checkTodayDate()}`,
      );

      if (!(typeof data !== 'object' || Array.isArray(data))) {
        if (data) {
          const disabledItems = Object.keys(data);
          if (disabledItems) {
            if (disabledItems[0]) {
              const modifiedItems: Record[] = items.map((item) => {
                return {
                  name: item,
                  disabled: disabledItems.includes(item),
                  icon: itemIcons[item],
                  finishedTime: data[item],
                };
              });
              setRecordItems(modifiedItems);
              return;
            }
          }
        }
      }
      setRecordItems(defaultItems);
    }

    checkDisabledItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);

  return (
    <StyledFullScreenCenterLayout styleIndex={styleIndex}>
      {recordItems ? (
        <StyledLayoutContainer>
          <UserNameWelcome />
          <ClockComponent />
          <Divider style={{ margin: '12px' }} light />
          <StyledEventContainer>
            {recordItems.map((item, index) => {
              return (
                <div style={{ width: '100%', position: 'relative' }}>
                  <Fab
                    style={{ width: '100%', padding: '40px', marginBottom: 20 }}
                    onClick={() => recordEventTime(item.name)}
                    key={index}
                    variant="extended"
                    color="inherit"
                    disabled={item.disabled}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <span className="button-text">{item.name}</span>
                      <span className="button-icon">{item.icon}</span>
                      <span className="button-time">{item.finishedTime}</span>
                    </Stack>
                  </Fab>
                  {item.disabled && (
                    // add function to re-record the event time
                    <div
                      onClick={() => {
                        const clonedRecordItems = [...recordItems];

                        const modifiedRecordItems = clonedRecordItems.map(
                          (loopItem) => {
                            if (loopItem.name === item.name) {
                              return {
                                name: loopItem.name,
                                disabled: false,
                                icon: loopItem.icon,
                                finishedTime: loopItem.finishedTime,
                              };
                            } else {
                              return loopItem;
                            }
                          },
                        );
                        setRecordItems(modifiedRecordItems);
                      }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 4,
                      }}
                    >
                      <RestoreIcon color="action" />
                    </div>
                  )}
                </div>
              );
            })}
            <Divider style={{ margin: '12px', width: '100%' }} light />
            <StyledLogoutButton
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </StyledLogoutButton>
          </StyledEventContainer>
        </StyledLayoutContainer>
      ) : (
        <Spinner />
      )}
    </StyledFullScreenCenterLayout>
  );
}

export default Main;

const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 70%;
`;

const StyledFullScreenCenterLayout = styled.div<{ styleIndex: number }>`
  background-image: ${(props) => style[props.styleIndex]};
  min-height: 100vh;
  width: 100%;

  padding-top: 12px;
  padding-bottom: 80px;
  .button-text {
    color: #000000;
    font-weight: bold;
    font-size: 14px;
  }
  .button-time {
    white-space: nowrap;
  }
`;

const StyledLogoutButton = styled.div`
  color: white;
  font-size: 2em;
  border: solid 1px #ffffff30;
  border-radius: 30px;
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
    border: solid 1px #00000030;
  }
`;
