import moment from "moment";

function convertMToMMFormat(time: number) {
    if (time < 10) {
        return `0${time}`
    } else {
        return time
    }
}

export function convertHourToTime(time) {
    const hour = Math.floor(time > 12 ? time - 12 : time)
    const minute = Math.floor(((time > 12 ? (time + 6 - 12) : (time + 6)) - hour) * 60)
    const second = Math.ceil((((time > 12 ? (time + 6 - 12) : (time + 6)) - hour) * 60 - minute) * 60)
    const a = time > 12 ? "pm" : "am"

    return `${hour}:${convertMToMMFormat(minute)}:${convertMToMMFormat(second)} ${a}`
}


export function convertTimeToHour(time: string) {
    console.log(time)
    const meridiem = time.split(" ")[1]
    const hour = parseInt(time.split(" ")[0].split(":")[0])
    const minute = parseInt(time.split(" ")[0].split(":")[1])
    const second = parseInt(time.split(" ")[0].split(":")[2])
    console.log(hour + ((meridiem === "pm") ? 12 : 0) + minute / 60 + second / 3600)
    return hour + ((meridiem === "pm") ? 12 : 0) + minute / 60 + second / 3600 - 5
}


export function checkTodayDate() {
    var currentTime = moment(new Date());
    var cutoffTime = moment('5:00am', 'h:mma');
    return currentTime.isBefore(cutoffTime) ? currentTime.subtract(1, "days").format("YYYY-MM-DD") : currentTime.format("YYYY-MM-DD")
}