import React, { ReactChild } from 'react';
import styled from "styled-components";

interface CenterLayoutProps {
    children: ReactChild,
}

function CenterLayout({ children }: CenterLayoutProps) {
    return <StyledCenterContainer>{children}</StyledCenterContainer>;
}

export default CenterLayout;

const StyledCenterContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
`