import * as React from 'react'
import { auth } from '../../firebase'

export interface FirebaseUser {
  displayName: string | null
  email: string | null
  photo: string | null
  uid: string
}

export interface FirebaseAuthContextValue {
  isAuthenticated: boolean | null
  firebaseUser: FirebaseUser | null
  isLoadingUserFromFirebase: boolean
}

export const FirebaseAuthContext = React.createContext<
  FirebaseAuthContextValue
>({
  isAuthenticated: null,
  firebaseUser: null,
  isLoadingUserFromFirebase: true,
})

export interface FirebaseAuthContextProviderProps {
  children: React.ReactNode
}

export const FirebaseAuthContextProvider = ({
  children,
}: React.PropsWithChildren<FirebaseAuthContextProviderProps>) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(
    !!auth.currentUser,
  )
  const [firebaseUser, setFirebaseUser] = React.useState<null | FirebaseUser>(
    null,
  )
  const [
    isLoadingUserFromFirebase,
    setIsLoadingUserFromFirebase,
  ] = React.useState(true)

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true)

        setFirebaseUser({
          displayName: user.displayName,
          email: user.email,
          photo: user.photoURL,
          uid: user.uid,
        })

        setIsLoadingUserFromFirebase(false)
      } else {
        setIsAuthenticated(false)
        setFirebaseUser(null)
        setIsLoadingUserFromFirebase(false)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const contextValue = {
    isAuthenticated,
    firebaseUser,
    isLoadingUserFromFirebase,
  }

  return (
    <FirebaseAuthContext.Provider value={contextValue}>
      {children}
    </FirebaseAuthContext.Provider>
  )
}
