import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RecordHistory } from '../../hook/useGetRecord';

interface RecordHistoryTableComponentProps {
    recordHistory: RecordHistory[]
}

function RecordHistoryTableComponent({ recordHistory }: RecordHistoryTableComponentProps) {
    return <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">wake</TableCell>
                    <TableCell align="right">breakfast</TableCell>
                    <TableCell align="right">lunch</TableCell>
                    <TableCell align="right">dinner</TableCell>
                    <TableCell align="right">sleep</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {recordHistory.map((row) => {
                    const date = Object.keys(row)[0]
                    const data = Object.values(row)[0]
                    return <TableRow
                        key={date}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {date}
                        </TableCell>
                        <TableCell align="right">{data.wake}</TableCell>
                        <TableCell align="right">{data.breakfast}</TableCell>
                        <TableCell align="right">{data.lunch}</TableCell>
                        <TableCell align="right">{data.dinner}</TableCell>
                        <TableCell align="right">{data.sleep}</TableCell>
                    </TableRow>

                })}
            </TableBody>
        </Table>
    </TableContainer>;
}

export default RecordHistoryTableComponent;
