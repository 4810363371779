import React from "react";
import styled from "styled-components";

const StyledSpinnerContainer = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;  
`

const StyledSpinner = styled.div<{ color: string }>`
  border: 10px solid #f3f3f3;
  border-top: 10px solid ${props => props.color};
  border-radius: 50%;
  aspect-ratio:auto  1/1;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface ISpinnerBlockProps {
  color?: string
}

export default function Spinner(props: ISpinnerBlockProps) {
  return <StyledSpinnerContainer>
    <StyledSpinner color={props.color || "#777ba8"} />
  </StyledSpinnerContainer>
}
