import React from 'react';
import styled from 'styled-components';
import { FirebaseAuthContext } from '../../provides/FirebaseAuthProvider/FirebaseAuthProvider';

function UserNameWelcome() {
    const { firebaseUser } = React.useContext(
        FirebaseAuthContext,
    )

    return (
        <StyledContainer>
            {firebaseUser &&
                <StyledText>
                    Welcome, {firebaseUser.displayName} !
                </StyledText>
            }
        </StyledContainer>
    )
}

export default UserNameWelcome;

const StyledContainer = styled.div`
    white-space:nowrap;
    display: flex;
    justify-content:center;
    align-items:center;
    padding:4px;
`

const StyledText = styled.div`
    font-size:24px;
    font-weight:bold;
    color:white;
`