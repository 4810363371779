import React, { ReactChild } from 'react';
import styled from 'styled-components';
import CenterLayout from './CenterLayout';

interface FullScreenCenterLayoutProps {
    children: ReactChild,
}

function FullScreenCenterLayout({ children, className }: FullScreenCenterLayoutProps & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <StyledFullScreenLayout className={className}>
            <CenterLayout>
                {children}
            </CenterLayout>
        </StyledFullScreenLayout>
    )
}

export default FullScreenCenterLayout;

const StyledFullScreenLayout = styled.div`
    width:100%;
    height:100%;
`