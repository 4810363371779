import { Database, getDatabase, set, ref, onValue, update } from "firebase/database";
import { FirebaseApp } from "firebase/app";
import { app } from "../../firebase";

function OnValuePromise(db: Database, path: string) {
    return new Promise((res, rej) => {
        try {
            onValue(ref(db, path), (payload) => {
                res(payload.val())
            })
        } catch (err) {
            rej(err)
        }
    })
}

function setPromise(db: Database, path: string, payload: object) {
    return new Promise((res, rej) => {
        try {
            set(ref(db, path), payload).then(data => {
                res(data)
            })
        } catch (err) {
            rej(err)
        }
    })
}

function updatePromise(db: Database, path: string, payload: object) {
    return new Promise((res, rej) => {
        try {
            update(ref(db, path), payload).then(data => {
                res(data)
            })
        } catch (err) {
            rej(err)
        }
    })
}

class FirebaseDatabase {
    private db: Database
    constructor(app: FirebaseApp) {
        this.db = getDatabase(app);
    }

    async get(path: string) {
        return await OnValuePromise(this.db, path)
    }

    async set(path: string, payload: object) {
        return await setPromise(this.db, path, payload)
    }

    async update(path: string, payload: object) {
        return await updatePromise(this.db, path, payload)
    }
}

export const firebaseDatabase = new FirebaseDatabase(app)