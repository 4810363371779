import React from 'react';
import { Redirect } from 'react-router-dom';

export interface UserRecord {
  [date: string]: any[];
}

function Dashboard() {
  return <Redirect to="/" />;
}

export default Dashboard;
