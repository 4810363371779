import React, { useEffect, useState } from 'react'
import moment from "moment"
import styled from "styled-components"

export default function ClockComponent() {
    const [value, setValue] = useState<string>(moment(new Date()).format("h:mm:ss a"));

    useEffect(() => {
        const interval = setInterval(
            () => setValue(moment(new Date()).format("h:mm:ss a")),
            1000
        );

        return () => {
            clearInterval(interval);
        }
    }, []);


    return (
        <StyledClockContainer>
            <StyledClockText>
                {value}
            </StyledClockText>
        </StyledClockContainer>
    )
}

const StyledClockContainer = styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
`

const StyledClockText = styled.div`
    font-size:36px;
    font-weight:bold;
    color:white;
`