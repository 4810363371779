import React, { ReactChild } from 'react';
import { useResponsive } from '../../provides/ResponsiveProvider/ResponsiveProvider';

interface DesktopLayoutProps {
    children: ReactChild
}

function DesktopLayout({ children }: DesktopLayoutProps) {
    const { isMobileLayout } = useResponsive()

    return !isMobileLayout ? <>{children}</> : null
}

export default DesktopLayout;
