import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAuthContextProvider } from './provides/FirebaseAuthProvider/FirebaseAuthProvider';
import { ResponsiveProvider } from './provides/ResponsiveProvider/ResponsiveProvider';

console.log('v0.1.0');

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthContextProvider>
      <ResponsiveProvider>
        <App />
      </ResponsiveProvider>
    </FirebaseAuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
