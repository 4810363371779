import { signInWithPopup, GoogleAuthProvider, Auth, AuthProvider } from "firebase/auth";
import { auth } from "../../firebase";
import { History } from 'history'

class FirebaseAuthentication {
    private auth: Auth

    constructor(auth: Auth) {
        this.auth = auth
    }
    authenticateUser = (provider: AuthProvider) => {
        this.auth.useDeviceLanguage()
        return signInWithPopup(this.auth, provider)
    }

    signIn = (provider: AuthProvider,
        history: History<unknown>,
        onAuthError: (errMessage: string) => void,) => {
        return () => {
            this.authenticateUser(provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    if (!credential) return
                    history.push("/")
                }).catch((err) => {
                    onAuthError(err.message)
                });
        }
    }
}

export const firebaseAuthentication = new FirebaseAuthentication(auth)
export const googleProvider = new GoogleAuthProvider();
