import React from 'react';
import { ReactChild } from 'react';
import { useResponsive } from '../../provides/ResponsiveProvider/ResponsiveProvider';

interface MobileLayoutProps {
    children: ReactChild
}

function MobileLayout({ children }: MobileLayoutProps) {
    const { isMobileLayout } = useResponsive()

    return isMobileLayout ? <>{children}</> : null
}

export default MobileLayout;
