import { Box, Button, List, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pages } from '../../config/pages';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';

function DrawerNavigationComponent() {
    const history = useHistory()
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setIsOpen(open)
    }

    const ListComponent = () => (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {pages.map((page, index) => {
                    return page.ready ? (
                        <ListItemButton key={page.label} onClick={() => history.push(page.path)}>
                            <ListItemIcon>
                                {page.icon}
                            </ListItemIcon>
                            <ListItemText primary={page.label} />
                        </ListItemButton >
                    ) : null
                })}
            </List>
        </Box>
    );

    return <StyledMenuContainer>
        <Button onClick={toggleDrawer(true)}>
            <MenuIcon fontSize="large" />
        </Button>
        <SwipeableDrawer
            anchor="right"
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <ListComponent />
        </SwipeableDrawer>
    </StyledMenuContainer>;
}

export default DrawerNavigationComponent;

const StyledMenuContainer = styled.div`
    position:absolute;
    top:0;
    right:0;

`