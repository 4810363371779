import React from 'react';
import { FirebaseAuthContext } from '../provides/FirebaseAuthProvider/FirebaseAuthProvider';
import { firebaseDatabase } from '../Services/firebase/database';

export interface RecordHistory {
    [date: string]: any
}

function useGetRecord() {
    const { firebaseUser } = React.useContext(
        FirebaseAuthContext,
    )
    const [recordHistory, setRecordHistory] = React.useState<RecordHistory[] | null>(null)

    React.useEffect(() => {
        if (!firebaseUser) return

        async function getUserRecords() {
            const data = await firebaseDatabase.get(`${firebaseUser?.email?.split("@")[0].replace(".", "-")}`)

            if (data && typeof data === "object" && !Array.isArray(data)) {
                const formattedData = Object.keys(data).map(date => {
                    return {
                        [date]: data[date]
                    }
                })
                setRecordHistory(formattedData)
            }
        }

        getUserRecords()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseUser])

    return { recordHistory };
}

export default useGetRecord;
