import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Main from './Router/Main';
import Dashboard from './Router/Dashboard';
import { FirebaseAuthContext } from './provides/FirebaseAuthProvider/FirebaseAuthProvider';
import Login from './Router/Login';
import styled from 'styled-components';
import AppLayout from './Component/AppLayout/AppLayout';
import History from './Router/History';

function App() {
  const { isAuthenticated, isLoadingUserFromFirebase } =
    React.useContext(FirebaseAuthContext);

  return (
    <StyledFullScreenBackgroundContainer>
      <>
        {!isLoadingUserFromFirebase && (
          <Router>
            {!isAuthenticated && <Redirect to="/login" />}
            {!isAuthenticated && <Route path="/login" component={Login} />}

            {isAuthenticated && (
              <AppLayout>
                <Switch>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/history" component={History} />
                  <Route path="/" component={Main} />
                </Switch>
              </AppLayout>
            )}
          </Router>
        )}
      </>
    </StyledFullScreenBackgroundContainer>
  );
}

export default App;

const StyledFullScreenBackgroundContainer = styled.div`
  min-height: 100vh;
`;
