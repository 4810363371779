import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import styled from 'styled-components';
import { FirebaseAuthContext } from '../provides/FirebaseAuthProvider/FirebaseAuthProvider';
import { firebaseAuthentication, googleProvider } from '../Services/firebase/authentication';

function Login() {
    const { isAuthenticated } = React.useContext(FirebaseAuthContext)
    const [authError, setAuthError] = React.useState<null | string>(null)

    const history = useHistory()

    const onAuthError = (errMessage: string) => {
        setAuthError(errMessage)
    }

    const signInWithGoogleCb = firebaseAuthentication.signIn(
        googleProvider,
        history,
        onAuthError
    )

    if (isAuthenticated) {
        return <Redirect to="/" />
    }

    return <StyledLoginLayoutContainer>
        <StyledLoginContainer>
            <div className="title">
                Daily Record
            </div>
            <GoogleLoginButton
                text={"Use Google Account"}
                align="center"
                onClick={signInWithGoogleCb}
                style={{
                    height: '40px',
                    fontSize: '16px',
                    width: 'calc(100%)',
                    borderRadius: '2px',
                    margin: '1rem 0 1rem',
                    boxShadow: 'none',
                    border: '1px solid #D9D9D9',
                }}
            />
            {authError && <div>Login fail</div>}
        </StyledLoginContainer>
    </StyledLoginLayoutContainer>;
}

export default Login;

const StyledLoginLayoutContainer = styled.div`
    background-image: url("/images/background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display:flex;
    min-height:100vh;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

const StyledLoginContainer = styled.div`
    padding:24px 16px;
    background-color:#00000020;

    
    .title{
        font-style:'italic';
        white-space:nowrap;
        font-size:2em;
        color:white;
    }
`