import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';

export const pages = [
    {
        label: "home",
        icon: <HomeIcon />,
        path: "/",
        ready: true
    },
    {
        label: "history",
        icon: <HistoryIcon />,
        path: "/history",
        ready: true
    },
    {
        label: "dashboard",
        icon: <LeaderboardIcon />,
        path: "/dashboard",
        ready: false
    },
]