import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pages } from '../../config/pages';

function BottomNavigationComponent() {
  const [value, setValue] = React.useState(0);
  const history = useHistory()

  return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    <BottomNavigation
      style={{ backgroundColor: "#ffffff30" }}
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
        history.push(pages[newValue].path)
      }}
    >

      {pages.map((page) => {
        return page.ready ? <BottomNavigationAction key={page.label} label={page.label} icon={page.icon} /> : null
      })}

    </BottomNavigation>
  </Paper>

}

export default BottomNavigationComponent;