import React, { ReactElement } from 'react';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import NightlightIcon from '@mui/icons-material/Nightlight';

export interface Record { name: string, disabled: boolean, icon: ReactElement, finishedTime: string }

export const items: string[] = [
    "wake",
    "breakfast",
    "lunch",
    "dinner",
    "sleep"
]

export const itemIcons: { [key: string]: ReactElement } = {
    "wake": <WbSunnyIcon />,
    "breakfast": <BreakfastDiningIcon />,
    "lunch": <LunchDiningIcon />,
    "dinner": <DinnerDiningIcon />,
    "sleep": <NightlightIcon />
}

export const defaultItems = items.map(item => {
    return {
        name: item,
        disabled: false,
        icon: itemIcons[item],
        finishedTime: ""
    }
})
const tableauColor = ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#CFECF9', '#7F7F7F', '#BCBD22', '#17BECF']

export const itemFillColor = (selectedItem): string => {
    const fillColor = {}
    items.forEach((item, index) => {
        fillColor[item] = tableauColor[index]
    })
    return fillColor[selectedItem]
}

