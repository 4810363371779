import React from 'react';
import { ReactChild } from 'react';
import styled from 'styled-components';
import BottomNavigationComponent from '../Navigation/BottomNavigationComponent';
import DrawerNavigationComponent from '../Navigation/DrawerNavigationComponent';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

interface AppLayoutProps {
  children: ReactChild
}

function AppLayout({ children }: AppLayoutProps) {
  return <StyledLayoutContainer>
    <DesktopLayout>
      <DrawerNavigationComponent />
    </DesktopLayout>
    {children}
    <MobileLayout>
      <BottomNavigationComponent />
    </MobileLayout>
  </StyledLayoutContainer>;
}

export default AppLayout;

const StyledLayoutContainer = styled.div`
  width:100%;
  height:100%;
`